<script>
import { Pie, mixins } from "vue-chartjs";

const { reactiveProp } = mixins;
export default {
  extends: Pie,
  mixins: [reactiveProp],
  props: ["chartData", "options"],
  computed: {
    language() {
      return this.$store.state.settings.language;
    }
  },
  watch: {
    language() {
      this.renderChart(this.chartData, this.options);
    },
    chartData() {
      this.renderChart(this.chartData, this.options);
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  }
};
</script>